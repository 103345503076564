import React from 'react'
import { Link, graphql } from 'gatsby'

// import Layout from '../components/layout'
import SEO from '../components/seo'
import { Transition, Spring } from 'react-spring'

const Blog = props => {
  // const postList = props.data.allMarkdownRemark
  // const instaList = props.data.allInstagramContent

  return (
    <div>
      {/* <Layout location={props.location}> */}
      <SEO title="Blog" />
      <section className="section blog-section">
        <h1 className="pinkback" en="">
          <div className=" pinkback_in title">Journal</div>
          <Spring from={{ width: '0px' }} to={{ width: '98px' }}>
            {props => <div style={props} className="pinkback_back " d="2" />}
          </Spring>
        </h1>
        <Spring from={{ width: '0px' }} to={{ width: '88px' }}>
          {props => <hr style={props} />}
        </Spring>
        {/* <div className="container">
          <h1 className="title">My Blog!</h1>
          {postList.edges.map(({ node }, i) => (
            <Link to={node.fields.slug} className="link">
              <div className="post-list">
                <h1>{node.frontmatter.title}</h1>
                <span>{node.frontmatter.date}</span>
                <p>{node.excerpt}</p>
              </div>
            </Link>
          ))}
          <Link to="/">Go back to the homepage</Link>
        </div> */}
      </section>
      {/* <section className=" section ig-section">
        <h1 className="pinkback" en="">
          <div className=" pinkback_in title">Instagram</div>
          <Spring from={{ width: '0px' }} to={{ width: '98px' }}>
            {props => <div style={props} className="pinkback_back " d="2" />}
          </Spring>
        </h1>
        <Spring from={{ width: '0px' }} to={{ width: '88px' }}>
          {props => <hr style={props} />}
        </Spring>
        {console.log('instaList', instaList)}
        {instaList.edges.map(({ node }, i) => (
          <a href={node.link} className="link" key={i}>
            <div className="post-list">
              <h1>{node.frontmatter.title}</h1>
              <span>{node.frontmatter.date}</span>
              <p>{node.excerpt}</p>
            </div>
            test
          </a>
        ))}
      </section> */}
      {/* </Layout> */}
    </div>
  )
}

export default Blog
// export const listQuery = graphql`
//   query ListQuery {
//     allInstagramContent {
//       edges {
//         node {
//           link
//           localImage {
//             childImageSharp {
//               fluid(maxHeight: 500, maxWidth: 500, quality: 100) {
//                 ...GatsbyImageSharpFluid_withWebp_tracedSVG
//               }
//             }
//           }
//           images {
//             standard_resolution {
//               width
//               height
//               url
//             }
//             low_resolution {
//               url
//             }
//           }
//         }
//       }
//     }
//   }
// `

// allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
//   edges {
//     node {
//       fields {
//         slug
//       }
//       excerpt(pruneLength: 250)
//       frontmatter {
//         date(formatString: "MMMM Do YYYY")
//         title
//       }
//     }
//   }
// }
